import { createContext } from 'react';

const defaultLocale = 'en';
const supportedLocales = ['fr', 'es', 'en'];

let locale;
if (typeof window !== 'undefined') {
  locale = window.location
    ? window.location.pathname.split('/')[1]
    : window.navigator.language.split('-')[0];

  // Check if locale is supported
  if (!supportedLocales.includes(locale)) {
    locale = defaultLocale;
  }
}

export const Locale = createContext({
  locale,
});
